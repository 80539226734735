*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
a{
  color: orange;
}

::-webkit-scrollbar{
  display: none;
}

.paddings{
  padding: 4rem;
}

.yPaddings{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.primaryText{
  font-size: 2rem;
  font-weight: bold;
}



@media screen and (max-width:768px) {
  .paddings{
    padding: 2rem;
  }

  .primaryText{
    font-size: 1.5rem;
  }

  .app{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
 
  
}
