.certificates>:nth-child(2){
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.slider-box{
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.cert-box>img{
    padding-top: 1rem;
    width: 70%;
    margin: auto;
}
.cert-box>:nth-child(2){
    text-align: center;
    margin-top: 1rem;
}

@media screen and (max-width: 500px){
    .cert-box>img{
        padding-top: 1rem;
        width: 90%;
        margin: auto;
    }
}